<form class="form-reset-pass" *ngIf="!expired else tokenExpired">
    <div class="row">
        <div class="col-lg-9 margin-auto">
            <h2 class="checkout-title">Password Reset Form</h2>
            <div class="row">            
                <div class="col-sm-6">
                    <label>Type New Password *</label>
                    <input type="password" name="password" class="form-control" required id="password">
                </div>
            </div>

            <div class="row">            
                <div class="col-sm-6">
                    <label>Re-type New Password *</label>
                    <input type="password" name="password" class="form-control" required id="password2">
                </div>
            </div>

           
            <div class="row form-reset-pass">                
                <button class="btn btn-outline-primary-2 margin-bottom" (click)="passResetClicked($event)">
                <span>Reset Password</span>                
                </button>                                                                     
            </div>
            
        </div>
    </div>
</form>
<ng-template #tokenExpired>
    <h4 class="display-center">The password reset roken has expired!</h4>
</ng-template>