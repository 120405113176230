
<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="form-box" >
        <div class="form-tab nav-fill">
            <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                <li ngbNavItem>
                    <a ngbNavLink>Sign In</a>
                    <ng-template ngbNavContent>
                        <form action="/login">
                            <div><b>EXISTING USER ACCOUNT</b> <br>
                                Please use your PHONE NUMBER as USERNAME and PASSWORD.
                                <font color=red>After login, it is EXTREMELY IMPORTANT to UPDATE your email address and CHANGE YOUR PASSWORD under My Account, 
                                    Account Details and save the changes for security reasons. 
                                    You will be able to login with your email address as username and your new password after that.</font>
                                <br><br>
                            </div>
                            <div class="form-group">
                                <label for="singin-email">Email address / Username *</label>
                                <input type="text" class="form-control" id="email" name="singin-email" required>
                               

                            </div>

                            <div class="form-group">
                                <label for="singin-password">Password *</label>
                                <input type="password" class="form-control" id="password" name="singin-password"
                                    required>
                            </div>

                            <div class="form-footer">
                                <button class="btn btn-outline-primary-2" (click)="loginClicked($event)">
                                    <span>LOG IN</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="signin-remember">
                                    <!--<label class="custom-control-label" for="signin-remember">Remember Me</label>-->
                                    <a href="javascript:;" class="forgot-link forgot-style"  (click)="forgotPassClicked()">Forgot Your Password?</a>
                                </div>

                                                                
                                <div class="no-account">
                                    Don't have an account? <a href="/register" class="forgot-link"> Click here to Register</a>
                                </div>
                                
                            </div>
                        </form>                       
                    </ng-template>
                </li>
      
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
