import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'molla-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})

export class ResetpasswordComponent implements OnInit{

  loaded:boolean;
  params:any;
  token:string;
  custid:string;
  constr:string;
  expired:boolean=false;

  constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private router:Router){
    this.activeRoute.queryParams.subscribe(async params => {
			this.loaded = false;
			this.params = params;

			if (params['token']) {
				this.token = params['token'];				
			}       
      if (params['custid']){
        this.custid = params['custid'];
      }
      if (params['constr']){
        this.constr = params['constr'];
        this.apiService.db_conn_string = "&dbConnString="+this.constr;
      }
    });
  }


  async ngOnInit(): Promise<void> {
    await this.checkTokenExpiry();
    
  }

  async checkTokenExpiry(){
    let sb:string;
    sb = ' SELECT CUST_PASS_TOKEN_EXPIRE_DATE ';
    sb += ' FROM CUSTOMERS '
    sb += ' WHERE CUSTID = ' + this.custid;
    let res:any =  await lastValueFrom(this.apiService.execSqlQueryConStrPromise(sb,this.constr));
    if(res.length > 0){
      if(Date.now() > Date.parse(res[0].CUST_PASS_TOKEN_EXPIRE_DATE)){
        this.expired = true;      
      }      
    }    

  }

  async passResetClicked(event:any){
    event.preventDefault();
    let pass = (<HTMLInputElement>document.getElementById("password")).value;
    let pass2 = (<HTMLInputElement>document.getElementById("password2")).value;

    if(pass === '' || pass2 === '')
      this.apiService.popupMsg(this.apiService.companyName,'Both fields are required', 'info');
    else if(pass != pass2)
            this.apiService.popupMsg(this.apiService.companyName,'Passwords do not match', 'error');
    else{
      /*
      let sb:string;
      sb = " UPDATE CUSTOMERS ";
      sb += " SET CUST_Password = '" + pass +"' ";
      sb += " WHERE CUSTID = " + this.custid;
      */
      let res = await lastValueFrom(this.apiService.updatePasswordPromise(this.custid,pass));
      if(res === 'success'){
        this.apiService.popupMsg(this.apiService.companyName,'Password updated successfully', 'success');
        this.router.navigate(['/']);
      }        
      else{
        this.apiService.popupMsg(this.apiService.companyName,'Something went wrong', 'error');
      }
    }
  };

}
