import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
	selector: 'molla-category-menu',
	templateUrl: './category-menu.component.html',
	styleUrls: ['./category-menu.component.scss']
})

export class CategoryMenuComponent implements OnInit, OnDestroy {

	isHome = true;
	sideMenuSubCategories = [];
	cats = [];
	masterCats = [];
	catsLinkFromParams='';//e.g. '/shop/sidebar/3cols'

	private subscr: Subscription;

	constructor(public utilsService: UtilsService, public router: Router, public apiService: ApiService) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.isHome = event.url === "/";
			} else if (event instanceof NavigationEnd) {
				this.isHome = event.url === "/";
			}
		})
	}

	async ngOnInit(): Promise<void> {
		this.catsLinkFromParams = this.apiService.catsLinkFromParams;
		if(typeof window !== 'undefined'){
			await this.apiService.fetchDBconnStringPromise();
			//this.apiService.slides = await this.apiService.fetchSlidesPromise();		
			this.cats = await this.apiService.setupMenuCategoriesandFetch();
			this.apiService.setupCategories4SideBar();
		}
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	toggleMenu() {
		document.querySelector('.category-dropdown .dropdown-menu').classList.toggle('show');
	}
}
