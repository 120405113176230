<div class="dropdown category-dropdown">
    <a href="javascript:;" class="dropdown-toggle" title="Categories" (click)="toggleMenu()">
        Categories</a>

    <div class="dropdown-menu" [class.hide]="!utilsService.isSticky && isHome">
        <nav class="side-nav">
            <ul class="menu-vertical sf-arrows">
                <li routerLinkActive="active" *ngFor="let cat of cats; let i = index">
                    <!--<a routerLink="/shop/sidebar/3cols" [queryParams]="{category: cat.PCM_Description,id:cat.CATID}">{{cat.PCM_Description}}</a>-->
                    <a>{{cat.PCM_Description}}</a>
                    <ul>                   
                        <li *ngFor="let subcat of cat.subcategories">
                            <a routerLink={{catsLinkFromParams}}  [queryParams]="{subcategory: subcat.CatID}">{{subcat.CatDescription}}</a>
                        </li>
                    </ul>                    
                </li>
               
            </ul>
        </nav>
    </div>
</div>