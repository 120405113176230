import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from 'src/app/shared/services/modal.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { introSlider, brandSlider } from '../data';
import { slider } from 'src/app/shared/classes/slider';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MetaService } from 'src/app/shared/services/meta.service';
import { lastValueFrom } from 'rxjs';
//import { log } from 'console';
import { domainMetaMap } from 'src/app/shared/services/meta-config.js';
import { Place, Result, Review } from 'src/app/shared/classes/google-reviews';
import { url } from 'inspector';





@Component({
	selector: 'molla-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

	product = [];
	new_products = [];
	loaded = false;
	new_loaded = false;
	posts = [];
	slides = [];
	slidesLoaded = false;	
	//introSlider = introSlider;
	brandSlider = brandSlider;
	popular_cats =[];
	popular_cats_img_path = '';
	brands_img_path = '';
	brands = [];
	popular_mster_cats_img_path='';
	catImgPath = '';
	customOptions:OwlOptions;
	domainName='';
	isShowCubeAd:boolean=false;
	showGooglReviews:boolean=false;
	greviews:Place;
	empty_star=[];
	full_star=[];
	

	constructor(public apiService: ApiService, 
		public utilsService: UtilsService, 
		private modalService: ModalService,
		private titleService: Title,
		private googleAnalytics: GoogleAnalyticsService,	
		private metasService: MetaService	
		) 		
	{
		
	}

	

	async ngOnInit(){		
		this.defineSliderOptions();							
		
		if (typeof window !== 'undefined') {
			this.domainName = window.location.hostname;
		}
		
		//this.titleService.setTitle('Home malakis');	
		this.metasService.updateMetaTags(this.domainName);

		if(typeof window!=='undefined'){
			await this.apiService.fetchDBconnStringPromise();

			this.googleAnalytics;

			if(this.apiService.show_master_categories_home_page == undefined)		
				await this.apiService.storeParams();		
					
			this.slides = await lastValueFrom(this.apiService.fetchSlidesPromise());
			
			if (this.slides.length > 0){
				this.slidesLoaded = true;
			}
			
			if (this.apiService.show_master_categories_home_page){
				this.popular_cats =  await lastValueFrom(await this.apiService.fetchMasterCategories());
				this.popular_mster_cats_img_path = environment.Images_Path + this.apiService.img_folder + "/master-cat/";
			}
			else if(!this.apiService.show_master_categories_home_page){
				this.popular_cats = await lastValueFrom(await this.apiService.fetchPopularCategories());
				this.popular_cats_img_path = environment.Images_Path+this.apiService.img_folder+"/popular/";
			}		
			this.apiService.confirmPlus18();	
			//let res = await lastValueFrom(this.apiService.fetchNewHotProductsPromise());
			let res = await lastValueFrom(this.apiService.fetchHotDealsProductsPromise());
			this.product= res.products;
			this.loaded = true;
			let res_new = await lastValueFrom(this.apiService.fetchNewProductsPromise());
			this.new_products = res_new.products;
			this.new_loaded = true;
			this.brands= await lastValueFrom(this.apiService.fetchBrandsPromise());
			this.brands_img_path = environment.Images_Path+ this.apiService.img_folder+"/brands/";		

			this.changeFavicon();			
			this.getStoresNamesContactPage();
			//load background image for cats
			await this.apiService.fetchCatsImg();
			this.catImgPath = '/assets/images/home/sliders/' + this.apiService.img_folder + '/' + this.apiService.home_cats_background_img;
		}
		
		//Check if there are items to show in cube ad first
		let res:any = await lastValueFrom(this.apiService.execSqlQuery("SELECT * FROM PRODUCTS WHERE DELETEDFLAG=0 AND PROD_POPUP_AD_WEB=1"));
		if (res && res.length > 0){
			// Adding a delay in seconds before executing the action
			setTimeout(() => {
				// Code to execute after the delay
				this.isShowCubeAd = true;
			}, 400);
		}		
		
		const meta = domainMetaMap[this.domainName];
		let placeid = meta.placeid;
		if(placeid){			
			const resss = await lastValueFrom(this.apiService.getGoogleReviews(placeid));		
			//let malakis=  JSON.parse(resss.replace(/\\n/g, '').replace(/\\"/g, '"'));
			this.greviews = new Place;
			this.greviews.result = new Result;
			this.greviews.result.url = "";
			this.greviews = JSON.parse(resss.replace(/\\n/g, ''));//.replace(/\\/g,''));			
			this.showGooglReviews=true;			
		}
		else{
			this.showGooglReviews=false;
		}		
	}//init ends

	changeFavicon()
	{		
		const fav = document.getElementById("favicon");
		fav.setAttribute("href", "/assets/images/"+this.apiService.img_folder+"/favicon.png");
	}

	async getStoresNamesContactPage(){
		let sb:string = 'SELECT * ';
		sb+= 'FROM WAREHOUSES ';
		sb+= 'WHERE DELETEDFLAG=0';

		this.apiService.stores = await this.apiService.execSqlQueryPromise(sb);		
	}	

	defineSliderOptions(){
		if(this.apiService.isMobile && this.apiService.isIOS === "iOS"){
			this.customOptions = {			
				autoplay:true,
				autoplaySpeed:200,
				loop: false,
				mouseDrag: true,
				touchDrag: true,
				pullDrag: false,
				dots: false,
				navSpeed: 800,
				items:1,
				navText: ['', ''],		//	&#8249    &#8250;									
				nav: false,			
			}				
		}
		else{
			this.customOptions = {			
				autoplay:true,
				autoplaySpeed:200,
				loop: true,
				mouseDrag: true,
				touchDrag: true,
				pullDrag: false,
				dots: false,
				navSpeed: 800,
				items:1,
				navText: ['', ''],		//	&#8249    &#8250;									
				nav: false,			
			}	
		}		
	}

	returnNumber(n:any){
		return parseInt(n);
	}

	calcemptystars(r:any){
		return Array(5-r);
	}

	calcfullstars(r:any){
		return Array(r);
	}
}
