import { Component, OnInit, ViewEncapsulation, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import imagesLoaded from 'imagesloaded';

import { Product } from 'src/app/shared/classes/product';
import { environment } from 'src/environments/environment';

import { ApiService } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { CompareService } from 'src/app/shared/services/compare.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { sliderOpt } from 'src/app/shared/data';
import { ModalService } from 'src/app/shared/services/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { lastValueFrom } from 'rxjs';

declare var $: any;

@Component({
	selector: 'molla-quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.scss'],
	encapsulation: ViewEncapsulation.None,

})

export class QuickViewComponent implements OnInit {
	@ViewChild('quantityInput') quantityInput: any;
	@Input() id='';
	slug='';
	product: Product;
	loaded = false;
	options = {
		...sliderOpt,
		dots: false,
		nav: false,
		loop: false,
		onTranslate: (e: any) => this.itemChange(e, this)
	}
	variationGroup = [];
	selectableGroup = [];
	sizeArray = [];
	colorArray = [];
	selectedVariant = {
		color: null,
		colorName: null,
		price: null,
		size: "",
		disabled: false
	};
	maxPrice = 0;
	minPrice = 99999;
	paddingTop = '100%';
	currentIndex = 0;
	qty = 1;
	qty2 = 1;	

	SERVER_URL = environment.SERVER_URL;

	@ViewChild('singleSlider') singleSlider: any;
	relatedArray =[];//{ id?: number; name?: string; price?: number; sale_price: number; pictures?: { width?: number; height?: number; url: number; }[]; }[];
	disableAddtoCart: boolean;
	has_video: boolean;
	prod_wharehouses: any;
	oldmax: number;
	oldmin: number;
	websiteurl: string;
	showStock:boolean;
	selectedRelated: any;
	varMaxPrice=0;
	varMinPrice=99999;
	variant_product_out_of_stock: boolean;
	customOptions:OwlOptions;
	imageLoaded:boolean=false;

	constructor(
		public apiService: ApiService,
		public cartService: CartService,
		public wishlistService: WishlistService,
		public compareService: CompareService,
		public utilsService: UtilsService,
		public router: Router,
		public el: ElementRef,
		public modalService: ModalService) {
			this.customOptions ={
				autoplay:true,
				autoplaySpeed:200,
				loop: true,
				mouseDrag: true,
				touchDrag: true,
				pullDrag: false,
				dots: false,
				navSpeed: 800,			
				navText: ['', ''],		//	&#8249    &#8250;						
				responsive: {
					0: {
						items: 1										
					},
					400: {
						items: 1
					},
					760: {
						items: 1
					},
					1000: {
						items: 1
					},
					1300:{
						items:1
					}
					},
				nav: false,	
				margin:0,
				autoplayHoverPause:true,
				autoHeight:true,
			}
	}

	public trackByFn(index, item) {
		if (!item) return null;
		return item.id;
	}

	async ngOnInit(): Promise<void> {				
		let tmp = this.slug;
		this.apiService.selectedRelated = {};
		let res:any;
		if(typeof window !== 'undefined')
			res = await lastValueFrom(this.apiService.getSingleProductPromise(this.id));
		this.product = res.product;
		this.imageLoaded = true;
		//this.apiService.getSingleProduct(this.id, true).subscribe(result => {
			//this.product = result.product;

		let min = this.minPrice;
		let max = this.maxPrice;

		this.variationGroup = this.product.variants.reduce((acc, cur) => {
			cur.size.map(item => {
				acc.push({
					color: cur.color,
					colorName: cur.color_name,
					size: item.name,
					price: cur.price
				});
			});
			if (min > cur.price) min = cur.price;
			if (max < cur.price) max = cur.price;
			return acc;
		}, []);

		if (this.product.var_rel != null){
			//add a dummy related for selection
			this.relatedArray = this.product.var_rel;
			//unshift its like push instead it adds the element on the first position
			this.relatedArray.unshift({id:'-1', name:'Select a Variant', price:'-1' });
			//disable add to cart if there are related (variants you need to select)
			this.disableAddtoCart = true;
		}

		if(this.product.videoURL)
		this.has_video= true;

		/**
		 * Get the wharehouses name where the products is available and display them
		 */
		if(typeof window !== 'undefined')
			this.prod_wharehouses = await lastValueFrom(await this.apiService.fetchProductWharehouses(this.product.id.toString()));

		if (this.product.variants.length == 0) {
			min = this.product.sale_price
				? this.product.sale_price
				: this.product.price;
			max = this.product.price;
		}
		else{
			/**if this product has related and the related products are on sale. 
			 * get the first related price and set it to min 
			 * get the first related sale price and set it to max. note that zero is the option to select variant*/
			
			//can be deleted 
			//min = this.product.var_rel[1].sale_price > 0 
			//	? this.product.var_rel[1].sale_price 
			//  : this.product.var_rel[1].price;
			//	max = this.product.var_rel[1].price;
			min=9999;
			max=0;
			//find the min price from all variants		
			this.product.var_rel.forEach(element => {
				if(element.sale_price > 0){
					if (min > element.sale_price){
						min=element.sale_price;
						this.oldmax = element.price;
					}
					if (max < element.price){
						max=element.price;
						this.oldmin = element.sale_price;
					}					
				}				
				else{
					if(min > element.price && element.price>0) //because the first one is the optin select variant
						min=element.price;
					if(max < element.price && element.price>0)
						max=element.price
				}						
				
				
			});
			//find the max price from all variants
		}

		this.minPrice = min;
		this.maxPrice = max;

		this.paddingTop = Math.floor((parseFloat(this.product.pictures[0].height.toString()) / parseFloat(this.product.pictures[0].width.toString()) * 1000)) / 10 + '%';

		this.refreshSelectableGroup();

		let self = this;
		imagesLoaded(".quickView-modal").on("done", function () {
			self.loaded = true;
		})
		this.showStock=this.apiService.showStock;
		this.websiteurl = window.location.href;

		if(this.product.stock <1 && this.product.var_rel == null)
			this.disableAddtoCart=true;
		//})
	}

	itemChange(e: any, self: any) {
		document.querySelector('#product-image-gallery').querySelector('.product-gallery-item.active').classList.remove('active');
		document.querySelector('#product-image-gallery').querySelectorAll('.product-gallery-item')[e.item.index].classList.add('active');

		self.currentIndex = e.item.index;
	}

	async addCart(event: Event, index=0) {
		event.preventDefault();
		let qtyin = this.quantityInput.value;

		if (qtyin < 1 || qtyin === ''){
			this.apiService.popupMsg('Quantity Cannot be Empty','Please type a number','info');
		}
		else if(qtyin > this.product.stock && this.product.var_rel == null){
			this.apiService.popupMsg('Out of Stock','Available stock: '+ this.product.stock,'info');
		}
		else{
			if ((event.currentTarget as HTMLElement).classList.contains('btn-disabled')) return;
		
			let newProduct = { ...this.product };
			
			if(this.product.var_rel != null){			
				//get new related's product info and send it as a prodcut to cart and not related cause thats how we treat it
				let res =  await lastValueFrom(this.apiService.getSingleProductPromise(this.selectedRelated.id));
				newProduct = res.product;
				if(qtyin > newProduct.stock){
					this.apiService.popupMsg('Out of Stock','Available stock: '+ newProduct.stock,'info');
					return;
				}
			}
			this.cartService.addToCart(
				newProduct, index == 0 ? this.qty : this.qty2
			);
		}
		/*
		if ((event.currentTarget as HTMLElement).classList.contains('btn-disabled')) return;

		let newProduct = { ...this.product };
		if (this.product.variants.length > 0) {
			newProduct = {
				...this.product,
				name:
					this.product.name +
					' - ' +
					this.selectedVariant.colorName +
					', ' +
					this.selectedVariant.size,
				price: this.selectedVariant.price
			};
		}

		this.cartService.addToCart(
			newProduct, this.qty
		);
		*/
	}

	async addToWishlist(event: Event) {
		event.preventDefault();
		if(this.product.var_rel != null && this.product.var_rel.length>0 && this.selectedRelated === undefined){
			this.apiService.popupMsg(this.apiService.company_name,"Please choose a variant first.","info");			
		}
		else if(this.selectedRelated != undefined){					
			//get new related's product info and send it as a prodcut to cart and not related cause thats how we treat it
			let res =  await lastValueFrom(this.apiService.getSingleProductPromise(this.selectedRelated.id));
			if(this.isInWishlist(res.product))
				this.apiService.popupMsg(this.apiService.company_name,"Producty already added.","info");		
			else
				this.wishlistService.addToWishList(res.product);
		}
		else {
			if (this.isInWishlist(this.product)) {	
				this.router.navigate(['/shop/wishlist']);
			} else {
				this.wishlistService.addToWishList(this.product);
			}
		}		
	}

	addToCompare(event: Event) {
		event.preventDefault();
		if (this.isInCompare()) return;
		this.compareService.addToCompare(this.product);
	}

	isInCompare() {
		return this.compareService.isInCompare(this.product);
	}

	isInWishlist(product:any) {
		return this.wishlistService.isInWishlist(product);
	}

	refreshSelectableGroup() {
		let tempArray = [...this.variationGroup];
		if (this.selectedVariant.color) {
			tempArray = this.variationGroup.reduce((acc, cur) => {
				if (this.selectedVariant.color !== cur.color) {
					return acc;
				}
				return [...acc, cur];
			}, []);
		}

		this.sizeArray = tempArray.reduce((acc, cur) => {
			if (acc.findIndex(item => item.size == cur.size) !== -1)
				return acc;
			return [...acc, cur];
		}, []);

		tempArray = [...this.variationGroup];
		if (this.selectedVariant.size) {
			tempArray = this.variationGroup.reduce((acc, cur) => {
				if (this.selectedVariant.size !== cur.size) {
					return acc;
				}
				return [...acc, cur];
			}, []);
		}

		this.colorArray = this.product.variants.reduce((acc, cur) => {
			if (
				tempArray.findIndex(item => item.color == cur.color) == -1
			) {
				return [
					...acc,
					{
						color: cur.color,
						colorName: cur.color_name,
						price: cur.price,
						disabled: true
					}
				];
			}
			return [
				...acc,
				{
					color: cur.color,
					colorName: cur.color_name,
					price: cur.price,
					disabled: false
				}
			];
		}, []);

		let toggle = this.el.nativeElement.querySelector('.variation-price');
		if (toggle) {
			if (this.selectedVariant.color && this.selectedVariant.size !== "") {
				$(toggle).slideDown();
			} else {
				$(toggle).slideUp();
			}
		}
	}

	selectColor(event: Event, item: any) {
		event.preventDefault();

		if (item.color == this.selectedVariant.color) {
			this.selectedVariant = {
				...this.selectedVariant,
				color: null,
				colorName: null,
				price: item.price
			};
		} else {
			this.selectedVariant = {
				...this.selectedVariant,
				color: item.color,
				colorName: item.colorName,
				price: item.price
			};
		}

		this.refreshSelectableGroup();
	}

	selectSize(event: Event) {
		if (this.selectedVariant.size == 'null') {
			this.selectedVariant = { ...this.selectedVariant, size: "" };
		}
		if ($(event.target).val() == "") {
			this.selectedVariant = { ...this.selectedVariant, size: "" };
		} else {
			this.selectedVariant = { ...this.selectedVariant, size: $(event.target).val() };
		}

		this.refreshSelectableGroup();
	}

	onChangeQty(current: number) {
		this.qty = current;
	}

	onChangeQty2(current: number) {
		this.qty2 = current;
	}

	clearSelection() {
		this.selectedVariant = {
			...this.selectedVariant,
			color: null,
			colorName: null,
			size: ""
		};
		this.refreshSelectableGroup();
	}

	closeQuickView() {
		let modal = document.querySelector('.quickView-modal') as HTMLElement;
		if (modal)
			modal.click();
	}

	changeImage($event: Event, i = 0) {
		this.currentIndex = i;
		this.singleSlider.to(i);
		$event.preventDefault();
	}

	async selectSizeRelated(event:any){
		let value = event.target.value;
		this.selectedRelated = this.relatedArray.find(x => x.name == value);
		this.apiService.selectedRelated = this.selectedRelated;
		
		//re-enter the min and max price for the related that has been selected
		let min=99999; //this.apiService.selectedRelated.sale_price;
		let max=0; //this.apiService.selectedRelated.sale_price;

		min = this.apiService.selectedRelated.sale_price
				? this.apiService.selectedRelated.sale_price
				: this.apiService.selectedRelated.price;
		max = this.apiService.selectedRelated.price;
		this.varMinPrice = min;
		this.varMaxPrice = max;

		this.variant_product_out_of_stock = false;
		let toggle = this.el.nativeElement.querySelector('.variation-price');
		if (toggle) {
			if (this.selectedRelated.price != "-1") {
				$(toggle).slideDown();
				this.disableAddtoCart = false;
				(<HTMLInputElement>document.getElementById('image_variant')).src = this.SERVER_URL + this.selectedRelated.pictures[0].url;	
				//check for stock on the specific selected variant
				if(this.selectedRelated.stock<1){
					//disable add to card and show getnotified
					this.variant_product_out_of_stock = true;
				}					
				else{//if not out of stock check wharehouse name where this is available
					this.prod_wharehouses = await lastValueFrom(await this.apiService.fetchProductWharehouses(this.selectedRelated.id.toString()));
				}			
			} else{
				this.disableAddtoCart = true;
				$(toggle).slideUp();				
				(<HTMLInputElement>document.getElementById('image_variant')).src = this.SERVER_URL + this.product.pictures[0].url;
				//get the wharehouse names for dummy or original product
				this.prod_wharehouses = await lastValueFrom(await this.apiService.fetchProductWharehouses(this.product.id.toString()));
			}

		}				
	}

	addToNotifyList(event:any, product:any){
		this.apiService.notify_arr.push(product);		
		this.modalService.showStockNotifyModal();				
	}

	addToNotifyListVariant(event:any){				
		this.apiService.notify_arr.push(this.selectedRelated);		
		this.modalService.showStockNotifyModal();				
	}

}