export const domainMetaMap={
    'localhost' : ['Malakies','Cyprus', 'Paradiso'],
    
    'www.titosecig.com': ['Cyprus'],
    
    'www.royalvape.com.cy': [
        'Andorra',
        'Austria',
        'Belarus',
        'Belgium',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Hungary',
        'Iceland',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Latvia',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Martinique',
        'Moldova',
        'Monaco',
        'Montenegro',
        'Netherlands',
        'Norway',
        'Poland',
        'Portugal',
        'Romania',
        'Russia',
        'San Marino',
        'Serbia',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Spain',
        'Sweden',
        'Switzerland',
        'Turkey',
        'United Kingdom',
        'United States',
        'Vatican City'
    ],
}