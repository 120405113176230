import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

//import { customSay, team, brands } from './about-one-data';
//import { sliderOpt } from 'src/app/shared/data';
import { ApiService } from 'src/app/shared/services/api.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/shared/services/meta.service';

@Component( {
	selector: 'pages-about-one',
	templateUrl: './about-one.component.html',
	styleUrls: [ './about-one.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush
} )

export class AboutOnePageComponent implements OnInit {

	@ViewChild("title1Elem") title1Elem: ElementRef;
	
	public vision;
	public mission;
	public who_we_are;
	public title1;
	public title2;
	public title3;
	public brands_text:string;
	public imageName;
	public imageURL;
	
	
	domainName:string;
	private subscr: Subscription;

	constructor (public apiService: ApiService, public googleAnalytics: GoogleAnalyticsService, private titleService:Title, private meta:MetaService) {		
	}

	async ngOnInit(){				
		this.titleService.setTitle('About Us');
		this.googleAnalytics;
		if (typeof window !== 'undefined') {
			this.domainName = window.location.hostname;
			if(this.apiService.db_conn_string == null || this.apiService.db_conn_string == ''){
				await this.apiService.fetchDBconnStringPromise();
			}
		}
		this.meta.updatePageMetaTags(this.domainName,'About');
		await this.getAboutPageElements();				
		this.imageURL = environment.Images_Path+"/" + this.apiService.img_folder + "/about/"+ (this.imageName || 'no-image');
		/*
		this.title1 = (this.apiService.title1 || '');
		this.vision = (this.apiService.vision || '');
		this.title2 = (this.apiService.title2 || '');
		this.mission = (this.apiService.mission || '');
		this.title3 = (this.apiService.title3 || '');
		this.who_we_are = (this.apiService.who_we_are || '');
		*/
	}

	async getAboutPageElements(){
		let sb:string ="";	
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = 2 ";
		sb += "AND IQWEB_PAGES.DELETEDFLAG=0 ";
		let res = await lastValueFrom(this.apiService.execSqlQueryPromise(sb));
		this.imageName = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "top image about").IQWPP_IMG;		
		this.title1 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 1").IQWPP_TEXT;				
		this.vision = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "our vision").IQWPP_TEXT;		
		//console.log(this.vision);
		this.title2 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 2").IQWPP_TEXT;		
		this.mission = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "our mission").IQWPP_TEXT;		
		this.title3 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 3").IQWPP_TEXT;		
		this.who_we_are = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "who we are").IQWPP_TEXT;															
	}

	
}
