<main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item active">About Us</li>
            </ol>
        </div>
    </nav>

    <div class="container">
        <div class="page-header page-header-big text-center" style="background-image: url({{imageURL.toString()}})"></div>
    </div>
    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3 mb-lg-0">
                    <h2 class="title">{{title1}}</h2>
                    <p id="vis">{{vision}} </p>
                    
                </div>
                {{vision}}
                <div class="col-lg-6">
                    <h2 class="title">{{title2}}</h2>
                    <p id="mis">{{mission}}</p>
                </div>
            </div>

            <div class="mb-5"></div>
        </div>

        <div class="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 mb-3 mb-lg-0">
                        <h2 class="title">{{title3}}</h2>
                        <p class="mb-2" id="who">{{who_we_are}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>