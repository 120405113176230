import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'molla-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

	@Input() containerClass = "container";
	@Input() isBottomSticky = false;

	year: any;
	version:any;
	footer_text:any;

	constructor(public apiService: ApiService) { 
	}

	async ngOnInit(): Promise<void> {
		if(typeof window !== 'undefined'){
			await this.apiService.fetchDBconnStringPromise();		
			this.getPageElements();
		}
		this.year = (new Date()).getFullYear();
		this.version = `${environment.version}`
	}

	async getPageElements(){
		let sb:string ="";
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = 3";

		let res = await lastValueFrom(this.apiService.execSqlQueryPromise(sb));
		this.footer_text = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "footer text").IQWPP_TEXT;		
	}
}
