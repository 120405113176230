<div class="container quickView-container container-style" >
    <button title="Close (Esc)" type="button" class="mfp-close" (click)="closeQuickView()">×</button>

    <div class="quickView-content horizontal skeleton-body">
        <div class="row skel-pro-single skel-quickview mb-0" [ngClass]="{loaded: loaded}">
            <div class="col-lg-6 p-0 pr-lg-2 mb-2 mb-lg-0">
                <div class="skel-product-gallery"></div>
                <div class="product-lg mb-1 position-relative" *ngIf="product">
                    <span class="product-label label-new" *ngIf="product.new">New</span>
                    <span class="product-label label-sale" *ngIf="product.sale_price && minPrice!=maxPrice">Sale</span>
                    <span class="product-label label-top" *ngIf="product.top">Top</span>
                    <span class="product-label label-out" *ngIf="(!product.stock || product.stock < 1) && showStock && product.variants.length < 1">Out Of Stock</span>
                        <!-- !product.stock || product.stock <1 && product.var_rel.length<0  -->


                    <molla-image [src]="SERVER_URL + product.pictures[0].url" *ngIf="product.pictures[0].url"></molla-image>
                    <owl-carousel-o carouselClasses="product-gallery-carousel owl-full owl-nav-dark" [options]="customOptions" *ngIf="imageLoaded"
                         ><!--id="owl-quickview" #singleSlider-->
                        <ng-container *ngFor="let item of product.pictures; let i = index;" >
                            <ng-template carouselSlide [id]="i.toString()" >                            
                                <molla-image [src]="SERVER_URL + item.url"></molla-image>
                            </ng-template>                              
                        </ng-container>                        
                    </owl-carousel-o>                    
                </div>
                
                <div class="product-sm row px-2" id="product-image-gallery" *ngIf="product">
                    <a href="#" [ngClass]="'product-gallery-item mb-0 ' + ( i === currentIndex ? ' active' : '' )"
                        (click)="changeImage( $event, i )" *ngFor="let item of product.pictures; let i = index;">
                        <molla-image [src]="SERVER_URL + product.sm_pictures[i].url" alt="Product side"
                            class="product-image">
                        </molla-image>                        
                    </a>
                </div>
            </div>
            <div class="col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0">
                <div class="entry-summary">
                    <div class="col-md-12">
                        <div class="entry-summary1 mt-2 mt-md-0"></div>
                    </div>
                    <div class="col-md-12">
                        <div class="entry-summary2"></div>
                    </div>
                </div>

                <div class="product-details w-100 pr-lg-4" *ngIf="product">
                    <h1 class="product-title">{{ product.name }}</h1>

                    <div class="ratings-container">
                        <div class="ratings">
                            <div class="ratings-val" [style.width]="product.ratings * 20 + '%'"></div>
                            <span class="tooltip-text">{{ product.ratings.toFixed(2) }}</span>
                        </div>
                        <span class="ratings-text">( {{ product.review }} Reviews )</span>
                    </div>
                    <!-- old price
                    <div class="product-price" *ngIf="(!product.stock || product.stock < 1) && product.variants.length < 1 else elseBlock">
                        <span class="out-price">€{{ product.price.toFixed(2) }}</span>
                    </div>

                    <ng-template #elseBlock>
                        <div class="product-price" *ngIf="minPrice == maxPrice else elseBlock">
                            €{{ minPrice.toFixed(2) }}</div>
                        <ng-template #elseBlock>
                            <div class="product-price" *ngIf="product.variants.length == 0 else elseBlock">
                                <span class="new-price">€{{ minPrice.toFixed(2) }}</span>
                                <span class="old-price">€{{ maxPrice.toFixed(2) }}</span>
                            </div>
                            <ng-template #elseBlock>
                                <div class="product-price">€{{minPrice.toFixed(2)}}&ndash;€{{maxPrice.toFixed(2)}}</div>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                    -->
                    <div class="product-price" *ngIf="(!product.stock || product.stock < 1) && showStock && product.variants.length < 1 else elseBlock">
                        <!--<span class="out-price">€{{ product.price.toFixed(2) }}</span>-->
                        <div class="product-price" *ngIf="minPrice == maxPrice  else elseBlockMinMax">€{{ minPrice.toFixed(2) }} </div>
                        <ng-template #elseBlockMinMax>
                            <div class="product-price">
                                <span class="new-price">€{{ minPrice.toFixed(2) }}</span>
                                <span class="old-price">€{{ maxPrice.toFixed(2) }}</span>
                            </div>
                        </ng-template>
                    </div>
                
                    <ng-template #elseBlock>
                        <div class="product-price" *ngIf="minPrice == maxPrice else elseBlock">€{{ minPrice.toFixed(2) }}</div>
                        <ng-template #elseBlock>
                            <div class="product-price" *ngIf="product.variants.length == 0  else elseBlock">
                                <span class="new-price">€{{ minPrice.toFixed(2) }}</span>
                                <span class="old-price">€{{ maxPrice.toFixed(2) }}</span>
                            </div>
                            <ng-template #elseBlock>
                                <div class="product-price" *ngIf="product.var_rel[1].sale_price == 0  else showSalePrice"> <!--product.var_rel[1].sale_price == 0 &&-->
                                    €{{minPrice.toFixed(2)}} - €{{maxPrice.toFixed(2)}}
                                </div>
                                <ng-template #showSalePrice>
                                    <div class="product-price" *ngIf="oldmax != maxPrice else showonlyminmax">
                                        <span class="new-price">€{{ minPrice.toFixed(2) }} - €{{oldmin.toFixed(2)}}</span>
                                        <span class="old-price">€{{oldmax.toFixed(2)}} - €{{ maxPrice.toFixed(2) }}</span>
                                    </div>                    
                             </ng-template>
                             <ng-template #showonlyminmax>
                                <div class="product-price">
                                    <span class="new-price">€{{ minPrice.toFixed(2) }}</span>
                                    <span class="old-price">€{{ maxPrice.toFixed(2) }}</span>
                                </div>
                             </ng-template>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                    <div class="product-vat">{{apiService.vattext}}</div>
                    <div class="product-content">
                        <p>{{ product.short_desc }}</p>
                    </div>

                     
                    <div *ngIf="product.variants.length > 0">
                        <!--
                        <div class="details-filter-row details-row-size">
                            <label>Color:</label>
                            <div class="product-nav product-nav-dots">
                                <a href="javascript:;" [class.active]="item.color == selectedVariant.color"
                                    [class.disabled]="item.disabled" [style.background-color]="item.color"
                                    *ngFor="let item of colorArray" (click)="selectColor($event, item)"></a>
                            </div>
                        </div>
                        -->
                        <div class="details-filter-row details-row-size">
                            <label for="size">Variant:</label>
                            <div class="select-custom">
                                <select name="size" id="size" class="form-control" [value]="selectedVariant.size"
                                    (change)="selectSizeRelated($event)">
                                    <option value="" [selected]="'' == selectedVariant.size">Select a Variant</option>
                                    <option [value]="item.size" *ngFor="let item of sizeArray"
                                        [selected]="item.size == selectedVariant.size">{{ item.size }}</option>
                                </select>
                            </div>

                            <!--<a href="javascript:;" class="size-guide mr-4"><i class="icon-th-list"></i>size guide</a>-->
                            <a href="javascript:;" (click)="clearSelection()"
                                *ngIf="selectedVariant.color || selectedVariant.size != ''">clear</a>
                        </div>
                    </div>
                
                    <div class="variation-price price-style">
                        <div class="product-price" *ngIf="!disableAddtoCart">
                           <!-- €{{ selectedVariant.price ? selectedVariant.price.toFixed( 2 ) : 0 }}
                           €{{ selectedRelated.price ? selectedRelated.price.toFixed( 2 ) : 0 }}    -->
                           <div class="product-price" *ngIf="varMinPrice != varMaxPrice  else elseBlock">
                            <span class="new-price">€{{ varMinPrice.toFixed(2) }}</span>
                            <span class="old-price">€{{ varMaxPrice.toFixed(2) }}</span>
                        </div>
                        <ng-template #elseBlock>
                            <div class="product-price">€ {{varMinPrice.toFixed(2)}}</div>
                        </ng-template>                 
                        </div>
                    </div>

                    <!--
                    <div class="variation-price" style="display: none;">
                        <div class="product-price">
                            €{{ selectedVariant.price ? selectedVariant.price.toFixed( 2 ) : 0 }}
                        </div>
                    </div>
                    -->

                    <div class="details-filter-row details-row-size">
                        <label for="qty">Qty:</label>
                        <molla-quantity-input [max]="product.stock" (changeQty)="onChangeQty( $event )" [value]="qty" #quantityInput>
                        </molla-quantity-input>
                    </div>
                    
                    

                    <div class="product-details-action">
                        <a href="javascript:;" class="btn-product btn-cart"
                            [class.btn-disabled]="disableAddtoCart" 
                            (click)="addCart( $event, 0 )"><span>add to cart</span></a><!--//!cartService.canAddToCart( product, qty2 ) || ( product.variants.length > 0 && !( selectedVariant.color && selectedVariant.size != '' ) )"-->

                        <div class="details-action-wrapper">
                            <a href="javascript:;" class="btn-product btn-wishlist"
                                 (click)="addToWishlist($event)"><!--[class.added-to-wishlist]="isInWishlist()"-->
                                <span>Add to Wishlist</span><!--{{ isInWishlist() ? 'Go' : 'Add' }}-->
                            </a>
                        </div>
                    </div>


                
                        
                        <br *ngIf="variant_product_out_of_stock"> 
                        <span *ngIf="product.stock <1 && showStock  && product.variants.length < 1" class="out-of-stock" (click)="addToNotifyList($event, product)"> Get notified when available</span>
                            <!-- *** this is only for products who have variants. when you select a variant it checks if is out of stock and then enables get notified-->
                            <span *ngIf="variant_product_out_of_stock" class="out-of-stock" (click)="addToNotifyListVariant($event)"> Get notified when available</span>
                            <br *ngIf="variant_product_out_of_stock"><br *ngIf="variant_product_out_of_stock">

                    <div class="product-details-footer">
                        <div class="product-cat w-100 text-truncate">
                            <span>Category:</span>
                            <span *ngFor="let cat of product.category; let i =index">
                                <a [routerLink]="['/shop/sidebar/list']" (click)="closeQuickView()"
                                    [queryParams]="{category: cat.slug}">{{ cat.name }}</a>{{ i < product.category.length - 1 ? ',' : '' }}</span>
                        </div>

                        <div class="social-icons social-icons-sm">
                            <span class="social-label">Share:</span>
                            <a target="_blank" href="https://www.facebook.com/sharer.php?u={{websiteurl}}&t={{product.name}}&db_conn_string={{apiService.shareProductConnStr}}" 
                                class="social-icon" title="Facebook"><i class="icon-facebook-f"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>