<form>
    <div class="row">
        <div class="col-lg-9 row-margin" >
            <h2 class="checkout-title">Billing Details</h2>
            <div class="row">
                <div class="col-sm-6">
                    <label>First Name *</label>
                    <input type="text" class="form-control" name="firstName" id="firstName" required>
                </div>

                <div class="col-sm-6">
                    <label>Last Name *</label>
                    <input type="text" class="form-control" name="lastName" id="lastName" required>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label>Company Name (Optional)</label>
                    <input type="text" class="form-control" name="company" id="company">
                </div>
                <div class="col-sm-6">
                    <label>Country *</label>
                    <!-- <input type="text" class="form-control" name="country" required id="country">-->
                    <select class="form-control" required id="country" name="country">
                        <option value="">Select a country
                        <option *ngFor="let country of countries" [value]="country">{{country}}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label>Street address *</label>
                    <input type="text" class="form-control" name="street1" placeholder="House number and Street name" id="street1" required>
                    <!-- <input type="text" class="form-control" name="street2" placeholder="Appartments, suite, unit etc ..." id="street2" required> -->
                </div>  
                <div class="col-sm-6">
                    <label>Town / City *</label>
                    <input type="text" name="city" class="form-control" required id="city">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label>Postcode / ZIP *</label>
                    <input type="text" name="postcode" class="form-control" required id="postcode">
                </div>

                <div class="col-sm-6">
                    <label>Phone *</label>
                    <input type="tel" name="tel" class="form-control" required id="tel">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label>Email address (Username) *</label>
                    <input type="email" name="email" class="form-control" required id="email">
                </div>
                <div class="col-sm-6">
                    <label>Password *</label>
                    <input type="password" name="password" class="form-control" required id="password">
                </div>
            </div>

           
            <div class="row">                
                <button class="btn btn-outline-primary-2 margin-style" (click)="registerClicked($event)" [disabled]="isProcessing">
                <span>Register</span>                
                </button>
            
            
                <div class="custom-control custom-checkbox margin-left-style" >
                    <input type="checkbox" class="custom-control-input" required id="terms">
                    <label class="custom-control-label" for="terms">I agree to privacy policy</label> 
                </div> 
                             
            </div>
            
        </div>
    </div>
</form>