import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegisterRoutingModule } from './register-routing.module';




@NgModule({
  declarations: [
    RegisterComponent
  ],
  imports: [
    CommonModule,    		
    RouterModule,
    SharedModule,  
    RegisterRoutingModule  		
  ]
})
export class RegisterModule { }
